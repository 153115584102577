import icons from "../icons/icons";
const $ = require("jquery"); // if we need
import Swup from "swup";
import Swiper, { Pagination, Parallax } from "swiper";
import "swiper/swiper-bundle.css";
Swiper.use([Parallax, Pagination]);
var app = {
  iconSpriteFn() {
    icons.forEach(iconSpriteFn);
    function iconSpriteFn(item, index) {
      const iconSprite = document.querySelector("#iconSprite");
      if (iconSprite) {
        iconSprite.innerHTML +=
          "<div class='icon-sprite__item'>" +
          "<span class='icon-sprite__number'>" +
          (index + 1) +
          "</span>" +
          "<div class='icon-sprite__preview'>" +
          item.iconSvg +
          "</div>" +
          "<div class='icon-sprite__name'>" +
          item.iconId +
          "</div>" +
          "</div>";
      }

      const icon = document.querySelectorAll(".icon");
      if (icon) {
        Array.prototype.forEach.call(icon, (el) => {
          let dataIconId = el.getAttribute("data-icon-id");
          if (dataIconId == item.iconId) {
            el.innerHTML = item.iconSvg;
          }
        });
      }
    }
  },
  load() {
    console.log("load");
  },
  resized() {
    console.log("resized");
  },

  headerMenuFn() {
    const headerLanguageBtn = document.querySelector(".js-header-menu-btn");
    if (headerLanguageBtn) {
      window.addEventListener("click", function (e) {
        if (document.querySelector(".header__menu").contains(e.target)) {
          headerLanguageBtn.parentNode.classList.toggle("opened");
        } else {
          headerLanguageBtn.parentNode.classList.remove("opened");
        }
      });
      window.addEventListener("click", function (e) {
        if (
          document.querySelector(".header__menu-wrapper").contains(e.target)
        ) {
          document
            .querySelector(".header__menu-wrapper")
            .parentNode.classList.add("opened");
        }
      });
    }
  },

  swiperTest() {
    var swiper = new Swiper(".js-hero-slider", {
      speed: 600,
      parallax: true,
      pagination: {
        el: ".js-hero-slider .swiper-pagination",
        clickable: true,
      },
    });
  },
  articleSlider() {
    var swiper = new Swiper(".js-article-slider", {
      speed: 600,
      slidesPerView: 3,
      loop: true,
      spaceBetween: 40,
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        // when window width is >= 480px
        769: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        // when window width is >= 640px
        993: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      },
      pagination: {
        el: ".js-article-slider .swiper-pagination",
        clickable: true,
      },
    });
  },
  inputFocusandTexted() {
    $(".input-item input, .input-item textarea").focus(function () {
      $(this).parent(".input-item").addClass("input-item--focused");
    });
    $(".input-item input, .input-item textarea").focusout(function () {
      if ($(this).val() === "") {
        $(this).parent(".input-item").removeClass("input-item--valued");
        $(this).parent(".input-item").removeClass("input-item--focused");
      } else {
        $(this).parent(".input-item").addClass("input-item--valued");
        $(this).parent(".input-item").removeClass("input-item--focused");
      }
    });
  },

  init: function () {
    app.iconSpriteFn();
    app.load();
    app.headerMenuFn();
    app.swiperTest();
    app.articleSlider();
    app.inputFocusandTexted();
  },
};

function docReadied(fn) {
  window.addEventListener("DOMContentLoaded", fn);
}
function docResized(fn) {
  window.addEventListener("resize", fn);
}
docReadied(() => {
  // const swup = new Swup({
  //   cache: true,
  // });
  // swup.on("contentReplaced", function () {
  //   swup.options.containers.forEach(() => {
  //     app.iconSpriteFn();
  //     app.swiperTest();
  //   });
  // });
  app.init();
});

docResized(() => {
  app.resized();
});

export default { docResized, docReadied };
